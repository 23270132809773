import { useEffect } from 'react';
import './index.css';
import { Transak, TransakConfig } from '@transak/transak-sdk';
export default function App() {
  const transakConfig: TransakConfig = {
    apiKey: process.env.REACT_APP_TRANSAK_API_KEY || '',
    environment: Transak.ENVIRONMENTS.PRODUCTION,
    themeColor: '#09091D',
    widgetHeight: '100vh',
    widgetWidth: '100vw',
    fiatCurrency: 'EUR',
    network: 'polygon',
    defaultCryptoCurrency: 'USDC',
    cryptoCurrencyCode: 'usdc',
    isFeeCalculationHidden: true,
    hideExchangeScreen: true,
    exchangeScreenTitle: 'Cashout to your bank account',
    productsAvailed: ['SELL'],
    countryCode: 'NL',
    containerId: 'transakMount',
    hideMenu: true,


  };
  const transak = new Transak(transakConfig);

  useEffect(() => {
    transak.init();


    // To get all SDK events
    Transak.on('*', (data) => {
      console.log(data);
    });

    // This will trigger when the user closed the widget
    Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
      console.log('Transak SDK closed!');
    });

    /*
    * This will trigger when the user has confirmed the order
    * This doesn't guarantee that payment has completed in all scenarios
    * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
    */
    Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, (orderData) => {
      console.log(orderData);
    });

    /*
    * This will trigger when the user marks payment is made
    * You can close/navigate away at this event
    */
    Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      transak.cleanup();
    });

  }, []);


  console.log("REACT_APP_TRANSAK_API_KEY", process.env.REACT_APP_TRANSAK_API_KEY);
  return (
    <div className="App">
      <div className='fullwidthScreen'>
        <div className='container'>
          <div className='titleContainer'>
            <img src='https://improved-bucket.s3.eu-central-1.amazonaws.com/D-Drops_Logo_trans.png' alt="Logo" className='logo' />
            <h1 className='heroTitle' >Cashout Portal</h1>
          </div>
          <div id="transakMount"
            className='transak'
          ></div>
        </div>
      </div>
    </div>
  );
}